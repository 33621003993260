body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-color: #4ECDC4;
}

.main.button {
  background-color: #F7FFF7;
  border-color: #F7FFF7;
  color: #4ECDC4;
  font-weight: 800;

  margin-bottom: 10px;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
}

body {
  background-color: #4ECDC4;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /*background-color: #1A535C;*/
  min-height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pdfPage > canvas {
  border-radius: 50px;
  margin-top: 10px;
}

.react-pdf__Page__annotations.annotationLayer {
  height: 0px;
}

.main.section {
  -webkit-flex-direction: column;
  flex-direction: column;
  display: -webkit-flex;
  display: flex;
}

.header.section {
  margin: auto;
  width: 100%;
}

.App-link {
  color: #4ECDC4;
}

.main.name {
  font-family: "Impact", Charcoal, sans-serif;
  font-size: 100px;
  color: #FFE66D;
  /*transition: color 1s ease-in-out,background-color 1s ease-in-out,border-color 1s ease-in-out,box-shadow 1s ease-in-out;*/
}

.main.name:hover {
  /*color: #FFE66D;*/
  -webkit-transition: color .5s ease-in-out,background-color 1s ease-in-out,border-color 1s ease-in-out,box-shadow 1s ease-in-out;
  transition: color .5s ease-in-out,background-color 1s ease-in-out,border-color 1s ease-in-out,box-shadow 1s ease-in-out;
}

.header.button {
  margin: 5px;
  width: 150px;
  font-size: 10px;
  padding: 3px;
}

.download.btn {
  margin-bottom: 10px;
  margin-top: 10px;
}

.button:hover {
  color: #FFE66D;
  border-color: #FFE66D;
  background-color: #F7FFF7;
  border-width: 1px 25px;
}

.btn {
  background-color: #F7FFF7;
  border-color: #F7FFF7;
  color: #4ECDC4;
  font-weight: 800;
}

.btn {
  -webkit-transition: .5s;
  transition: .5s
}

.header.name {
  color: #FFE66D;
  margin: 5px;
  font-family: "Impact", Charcoal, sans-serif;
}

.project.entry {
  width: 300px;
  height: 300px;
  border-radius: 25px;
  background-color: #44B581;
  margin: 5px;
  position: relative;
}

.project.entry.outer {
  background-size: cover;
}

.project.entry.inner {
  position: absolute;
  width: 300px;
  background-color:#474a4f;
  color: #FFF;
  margin:0px;
  opacity: 0.0;
  -webkit-transition: .5s;
  transition: .5s;
  color: white;
  overflow-y:auto;
  overflow-x: hidden;
}

.project.entry.inner:hover{
  z-index:300;
  width: 400px;
  opacity: .9;
  color: #FFF;
  -webkit-transition: .5s;
  transition: .5s;
}

.project.section {
  display: -webkit-flex;
  display: flex;
}

.project.name {
  font-size: 12px;
}

.project.desc {
  font-size: 12px;
  margin:20px;
  text-align: justify;
}

.project.desc.container {
  width:360px;
}

.about.main {
  width: 50%;
  text-align: justify;
  font-size: 12px;
}
